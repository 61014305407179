import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Form, Input, Upload, Space } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import AddUpdateClient from "../../../components/clientsForm/clientsForm"
import { getClientDetails } from "store/clients/action"
//i18n
import { withTranslation } from "react-i18next"

const NewClient = props => {
  const [form] = Form.useForm()
  document.title = "Branding | TRUST"
  const location = useLocation()
  const dispatch = useDispatch()
  const { applicationOneClientsDetails } = useSelector(
    state => state.AppClients
  )
  const { state } = location

  useEffect(() => {
    if (state?.record) {
      const payload = {
        application_type: 1,
        client_id: +state?.record.id,
      }
      dispatch(getClientDetails(payload))
    }
  }, [state?.record])

  return (
    <React.Fragment>
      <AddUpdateClient
        typeId={1}
        backTo='/clients-list'
        clientsDetails={applicationOneClientsDetails}
      />
    </React.Fragment>
  )
}

NewClient.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(NewClient)
