import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Form,
  Input,
  Upload,
  Space,
  Typography,
  Spin,
  Image,
  notification,
  Button,
} from "antd"
import { Container, Row, Col } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { validatePhoneNumber, validatePostcode } from "../../utils/validators"
import Breadcrumb from "../Common/Breadcrumb"

import { createClients, resetAppClientsStates } from "store/clients/action"

//i18n
import { withTranslation } from "react-i18next"
const { Text, Title } = Typography
const AddUpdateClient = props => {
  document.title = "Create Clients | TRUST"
  const { typeId, backTo } = props
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, success } = useSelector(state => state.AppClients)
  const [image, setImage] = useState()
  const [logoFile, setLogoFile] = useState()
  console.log("App-Client-id-", typeId)

  useEffect(() => {
    if (success) {
      form.resetFields()
      dispatch(resetAppClientsStates())
      navigate(backTo)
    }
  }, [success])

  const handleUploadChange = info => {
    const file = info.file

    if (!file.type.startsWith("image/")) {
      notification.error({ message: "Please upload a valid image file." })
      return
    }

    createImageBitmap(file)
      .then(imgBitmap => {
        if (imgBitmap.width === 1024 && imgBitmap.height === 244) {
          setLogoFile(file)
          setImage(URL.createObjectURL(file))
        } else {
          notification.error({ message: "The image must be 1024x244 pixels." })
        }
      })
      .catch(() => {
        notification.error({
          message: "Error processing the image. Please try again.",
        })
      })
  }

  const validateTemperatureRange = (_, value) => {
    const minTemp = form.getFieldValue("minimumtemperature")
    const maxTemp = form.getFieldValue("maximumtemperature")
    if (minTemp > maxTemp) {
      return Promise.reject(
        "Minimum temperature cannot be greater than maximum temperature"
      )
    }
    return Promise.resolve()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Cave" breadcrumbItem="Add New Client" />
          <Row>
            <Col lg={6}>
              <Title level={4}>Create New Client</Title>
            </Col>
            <Col lg={6}>
              <Button
                type="primary"
                style={{
                  float: "right",
                }}
                disabled={loading}
                onClick={() => {
                  navigate(backTo)
                }}
              >
                Back to list
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form
                form={form}
                layout="vertical"
                size="large"
                initialValues={{
                  primarycolor: "#2296F3",
                  primarydark: "#1670B6",
                  secondarycolor: "#1670B6",
                  accentcolor: "#1670B6",
                  backgroundcolor: "#FFFFFF",
                  titleTextColor: "#FFFFFF",
                  headerTextColor: "#000000",
                  buttonTextColor: "#FFFFFF",
                }}
                onFinish={values => {
                  values.typeId = typeId
                  if (typeId === 1) {
                    values.logo = logoFile
                  }
                  dispatch(createClients(values))
                  console.log("values--sd-fas-f->", values)
                }}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={
                //   requiredMark === "customize"
                //     ? customizeRequiredMark
                //     : requiredMark
                // }
                autoComplete="off"
              >
                {/* client Info */}
                <>
                  <Form.Item>
                    <Form.Item
                      name="client_name"
                      label="Client Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Client Name!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Enter Client Name" />
                    </Form.Item>
                    <Form.Item
                      name="username"
                      label="User Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your User Name!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="Enter User Name" />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="firstname"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Enter First Name" />
                    </Form.Item>
                    <Form.Item
                      name="lastname"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="Enter Last Name" />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="contactnumber"
                      label="Contact Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Contact Number!",
                        },
                        { validator: validatePhoneNumber },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Enter Contact Number" />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Email",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="Enter Your Email" />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="AddressLine1"
                      label="Address line 1"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Address line 1!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Enter Address Line one" />
                    </Form.Item>
                    <Form.Item
                      name="AddressLine2"
                      label="Address line 2"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Address line 2!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="Enter Address line 2" />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="city"
                      label="City"
                      rules={[
                        {
                          required: true,
                          message: "Please input your City!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Enter City" />
                    </Form.Item>
                    <Form.Item
                      name="country"
                      label="Country"
                      rules={[
                        {
                          required: true,
                          message: "Please input Country",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="Enter Country" />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="state"
                      label="State"
                      rules={[
                        {
                          required: true,
                          message: "Please input your State!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Enter State" />
                    </Form.Item>
                    <Form.Item
                      name="postcode"
                      label="Post Code"
                      rules={[
                        {
                          required: true,
                          message: "Please input Post Code",
                        },
                        { validator: validatePostcode },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="Enter Post Code" />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="geofencing_radius"
                      label="Geofencing Radius"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Geofencing Radius!",
                        },
                      ]}
                      style={{
                        display: typeId === 1 ? "inline-block" : "",
                        width: typeId === 1 ? "calc(50% - 8px)" : "",
                      }}
                    >
                      <Input
                        type="number"
                        placeholder="Enter Geofencing Radius"
                        min={0}
                      />
                    </Form.Item>
                    {typeId === 1 && (
                      <Form.Item
                        name="access_code"
                        label="Access Code"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Access Code!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input
                          type="text"
                          placeholder="Enter Access Code"
                          min={0}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          // Add your password validation rules here if needed
                          message: "Please input your password!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input.Password placeholder="Enter Password" />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      label="Confirm Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error("The two passwords do not match!")
                            )
                          },
                        }),
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                  </Form.Item>
                  {typeId === 1 && (
                    <Form.Item>
                      <Form.Item label="Cover">
                        <Upload
                          name="cover_image"
                          listType="picture"
                          maxCount={1}
                          onChange={handleUploadChange}
                          beforeUpload={() => false}
                          showUploadList={false}
                        >
                          <Button icon={<UploadOutlined />}>
                            Upload Cover
                          </Button>
                        </Upload>
                        <div style={{ marginTop: "16px" }}></div>
                        {image && (
                          <Image width={1024} height={244} src={image} />
                        )}
                      </Form.Item>
                    </Form.Item>
                  )}
                </>
                {/* Temperature */}
                {typeId === 1 &&<>
                  <Title level={4}>Temperature</Title>
                  <Form.Item>
                    <Form.Item
                      name="minimumtemperature"
                      label="Minimum Temperature"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Minimum Temperature!",
                        },
                        {
                          validator: validateTemperatureRange,
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="10 C" />
                    </Form.Item>
                    <Form.Item
                      name="maximumtemperature"
                      label="Maximum Temperature"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Maximum Temperature!",
                        },
                        {
                          validator: validateTemperatureRange,
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="100 C" />
                    </Form.Item>
                  </Form.Item>
                </>}
                {/* color Info */}
                <>
                  <Title level={4}>Branding Information</Title>
                  <Form.Item>
                    <Form.Item
                      name="primarycolor"
                      label="Primary Color"
                      rules={[
                        {
                          // required: true,
                          message: "Please input your Primary Color!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input
                        className="form-control form-control-color w-100"
                        type="color"
                        id="example-color-input8"
                      />
                    </Form.Item>
                    <Form.Item
                      name="primarydark"
                      label="Primary Dark"
                      rules={[
                        {
                          // required: true,
                          message: "Please input your Primary Dark!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input
                        className="form-control form-control-color w-100"
                        type="color"
                        id="example-color-input7"
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="secondarycolor"
                      label="Secondary Color"
                      rules={[
                        {
                          // required: true,
                          message: "Please input your Secondary Color!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input
                        className="form-control form-control-color w-100"
                        type="color"
                        id="example-color-input6"
                      />
                    </Form.Item>
                    <Form.Item
                      name="accentcolor"
                      label="Accent Color"
                      rules={[
                        {
                          // required: true,
                          message: "Please Enter Accent Color",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input
                        className="form-control form-control-color w-100"
                        type="color"
                        id="example-color-input5"
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="backgroundcolor"
                      label="Background Color"
                      rules={[
                        {
                          // required: true,
                          message: "Please input your Background Color!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input
                        className="form-control form-control-color w-100"
                        type="color"
                        id="example-color-input4"
                      />
                    </Form.Item>
                    <Form.Item
                      name="titleTextColor"
                      label="Title Text Color"
                      rules={[
                        {
                          // required: true,
                          message: "Please input your Title Text Color!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input
                        className="form-control form-control-color w-100"
                        type="color"
                        id="example-color-input1"
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="headerTextColor"
                      label="Header Text Color"
                      rules={[
                        {
                          // required: true,
                          message: "Please input your Header Text Color!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input
                        className="form-control form-control-color w-100"
                        type="color"
                        id="example-color-input2"
                      />
                    </Form.Item>
                    <Form.Item
                      name="buttonTextColor"
                      label="Button Text Color"
                      rules={[
                        {
                          // required: true,
                          message: "Please Enter Button Text Color",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input
                        className="form-control form-control-color w-100"
                        type="color"
                        id="example-color-input3"
                      />
                    </Form.Item>
                  </Form.Item>
                </>
                {/* hub info */}
                {typeId === 2 && (
                  <>
                    <Title level={4}>Hub Information</Title>
                    <Form.Item>
                      <Form.Item
                        name="hubusername"
                        label="Hub User Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Hub User Name!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Hub User Name" />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="hupIpAddress"
                        label="Hub Ip Address"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Hub Ip Address!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input type="text" placeholder="Enter Hub Ip Address" />
                      </Form.Item>
                      <Form.Item
                        name="hubport"
                        label="Hub Port"
                        rules={[
                          {
                            required: true,
                            message: "Please input Hub Port",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input
                          type="number"
                          min={0}
                          placeholder="Enter Hub Port"
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="hubpassword"
                        label="Hub Password"
                        rules={[
                          {
                            // Add your password validation rules here if needed
                            message: "Please input your Hub Password!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input.Password placeholder="Enter Hub Password" />
                      </Form.Item>
                      <Form.Item
                        name="hubconfirmPassword"
                        label="Hub Confirm Password"
                        dependencies={["hubpassword"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message:
                              "Please confirm your Hub Confirm Password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("hubpassword") === value
                              ) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                new Error("The two passwords do not match!")
                              )
                            },
                          }),
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input.Password placeholder="Confirm Password" />
                      </Form.Item>
                    </Form.Item>
                  </>
                )}
                <Form.Item>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button type="primary" htmltype="submit" disabled={loading}>
                      {loading ? <Spin /> : "Save"}
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddUpdateClient.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AddUpdateClient)
