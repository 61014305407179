import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { Link, useNavigate } from "react-router-dom"
import { Form, Table, Select, DatePicker, TimePicker, Typography } from "antd"
import { Container, Row, Button, Col } from "reactstrap"
import ReactQuill from "react-quill"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import dayjs from "dayjs"
import { getPrivacy, updatePrivacy } from "store/resources/action"
import { useDispatch, useSelector } from "react-redux"
const { Title, Text } = Typography
//i18n
import { withTranslation } from "react-i18next"
const { Option } = Select
const PrivacyPolicy = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, privacyData } = useSelector(state => state.ResourcesReducer)
  const [value, setValue] = useState(privacyData.details)

  const handleChange = content => {
    setValue(content)
  }

  useEffect(() => {
    dispatch(getPrivacy())
  }, [])

  useEffect(() => {
    if (privacyData.details) {
      setValue(privacyData.details)
    }
  }, [privacyData.details])

  const handleSaveChanges = () => {
    const payload = {
      details: value,
    }
    dispatch(updatePrivacy(payload))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb dashboardRoute={'/letzero-dashboard'} title={'Letzero'} breadcrumbItem="Privacy Policy" />
          <Row>
            <Col>
              <Title level={3}>Privacy Policy</Title>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <ReactQuill
                value={value}
                onChange={handleChange}
                // theme="snow"
                modules={{ toolbar: true }}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Button
                className="bg-primary text-white"
                style={{
                  float: "right",
                }}
                onClick={handleSaveChanges}
                disabled={loading}
              >
                Save Changes
              </Button>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

PrivacyPolicy.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PrivacyPolicy)
