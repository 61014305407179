export const actionTypes = {
  // get user profile
  GET_USER_PROFILE: "GET_USER_PROFILE",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  // update profile
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  //   rest user Profile states
  RESET_USER_PROFILE: "RESET_USER_PROFILE",
}

// get user
export const getUserProfile = () => ({
  type: actionTypes.GET_USER_PROFILE,
})

export const getUserProfileSuccess = payload => ({
  type: actionTypes.GET_USER_PROFILE_SUCCESS,
  payload,
})

// update
export const updateUserProfile = payload => ({
  type: actionTypes.UPDATE_PROFILE,
  payload,
})

// reset next of kin
export const resetUserProfile = () => ({
  type: actionTypes.RESET_USER_PROFILE,
})
