import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Form,
  Table,
  Select,
  DatePicker,
  TimePicker,
  Typography,
  Input,
  Space,
  Button,
} from "antd"
import { Container, Row, Col } from "reactstrap"
import Breadcrumb from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
const { Title, Text } = Typography
//i18n
import { withTranslation } from "react-i18next"
const { Option } = Select

const LandlordForm = props => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onFinish = values => {
    console.log("Form values:", values)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            dashboardRoute={"/letzero-dashboard"}
            title={"Letzero"}
            breadcrumbItem="Privacy Policy"
          />
          <Row>
            <Col>
              <Title level={3}>Create New Landlord</Title>
            </Col>
          </Row>

          <Form
            form={form}
            layout="vertical"
            size="large"
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Landlord Name"
                  name="landlordName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the landlord name",
                    },
                  ]}
                >
                  <Input placeholder="Landlord Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the contact number",
                    },
                  ]}
                >
                  <Input placeholder="+44 (0) 1206 873333" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input placeholder="Email@gmail.com" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Address Line 1"
                  name="addressLine1"
                  rules={[
                    { required: true, message: "Please enter address line 1" },
                  ]}
                >
                  <Input placeholder="Wivenhoe Park" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Address Line 2" name="addressLine2">
                  <Input placeholder="Colchester CO4 3SQ" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Postcode"
                  name="postcode"
                  rules={[
                    { required: true, message: "Please enter the postcode" },
                  ]}
                >
                  <Input placeholder="GU16 7HF" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Add
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(LandlordForm)
