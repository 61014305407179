import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from 'redux-persist/integration/react';
import "./i18n"
import { Provider } from "react-redux"
import { ConfigProvider, theme } from "antd"
import store, { persistor } from "./store"
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#CF2A27",
            },
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

