import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Form, Input, Button, Card, Typography, Spin, Row, Col } from "antd"
import * as Yup from "yup"
import { useFormik } from "formik"
import { loginUser, apiError, resetLoginStates } from "../../store/actions"
import logoSm from "../../assets/images/trust-logo.png"

const { Title } = Typography

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [userLogin, setUserLogin] = useState({ email: "", password: "" })

  const { user } = useSelector(state => state.Account)
  const { loading, success } = useSelector(state => state.Login)

  useEffect(() => {
    if (user) {
      setUserLogin({ email: user.email, password: user.password })
    }
  }, [user])

  useEffect(() => {
    if (success) {
      navigate("/buddy-dashboard")
      dispatch(resetLoginStates())
    }
  }, [success])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userLogin.email || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values))
    },
  })

  return (
    <div
      className="account-pages"
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        padding: "12px",
      }}
    >
      <Row justify="center" align="middle" style={{ width: "100%" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className="text-center">
            <img src={logoSm} alt="logo" style={{ width: "50%" }} />
            <Title level={2} style={{ marginTop: 20 }}>
              Heating for Humans
            </Title>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card>
            <Title level={3} style={{ textAlign: "center" }}>
              Welcome To Trust!
            </Title>
            <Form layout="vertical"  size="large" onFinish={validation.handleSubmit}>
              <Form.Item
                label="Email Address"
                validateStatus={
                  validation.touched.email && validation.errors.email
                    ? "error"
                    : ""
                }
                help={validation.touched.email && validation.errors.email}
              >
                <Input
                  name="email"
                  placeholder="Email address"
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </Form.Item>

              <Form.Item
                label="Password"
                validateStatus={
                  validation.touched.password && validation.errors.password
                    ? "error"
                    : ""
                }
                help={validation.touched.password && validation.errors.password}
              >
                <Input.Password
                  name="password"
                  placeholder="Password"
                  value={validation.values.password}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </Form.Item>

              <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                <Button type="primary" htmlType="submit" block disabled={loading} >
                  {loading ? <Spin /> : "Sign In"}
                </Button>
              </Form.Item>
            </Form>
            {/* <div className="text-center">
              <Link to="/forgot-password">Forgot your password?</Link>
            </div> */}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

Login.propTypes = {
  error: PropTypes.any,
}

export default Login
