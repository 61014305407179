import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Switch, Table, Avatar, Button } from "antd"
import { Container, Row, Col } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { getApplicationClients, clientStatusChange } from "store/clients/action"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch, shallowEqual } from "react-redux"

const CavClientsList = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [clientsList, setClientList] = useState([])
  const { applicationTwoClients } = useSelector(
    state => state.AppClients,
    shallowEqual
  )

  useEffect(() => {
    if (applicationTwoClients) {
      const modifiedArray = applicationTwoClients.map(client => ({
        ...client,
        name: `${client.first_name} ${client.last_name}`,
      }))
      setClientList(modifiedArray)
    }
  }, [applicationTwoClients])

  useEffect(() => {
    const application_type = 2
    dispatch(getApplicationClients(application_type))
  }, [])

  function statusChange(data) {
    if (data === 1) {
      return 0
    } else if (data === 0) {
      return 1
    } else {
      return null
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Image",
      dataIndex: "profile_image",
      align: "center",
      render: data => <Avatar src={data} />,
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Contact",
      dataIndex: "mobile",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (data, record) => (
        <Switch
          style={{
            backgroundColor: data === 1 ? "green" : "red",
          }}
          onChange={() => {
            const payload = {
              application_type: 2,
              org_id: record?.id,
              value: statusChange(data),
            }
            dispatch(clientStatusChange(payload))
          }}
          checked={data === 1 ? true : false}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (data, record) => (
        <Button
          type="primary"
          onClick={() =>
            navigate("/cave-update-client", {
              state: { record, backToList: "/cave-clients-list" },
            })
          }
        >
          Edit
        </Button>
      ),
    },
  ]

  document.title = "Clients List | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Cave"
            dashboardRoute="/cave-dashboard"
            breadcrumbItem="Clients List"
          />
          <Row>
            <Col>
              <Button
                type="primary"
                style={{
                  float: "right",
                }}
                onClick={() => navigate("/cave-new-client")}
              >
                + Add New Client
              </Button>
            </Col>
          </Row>
          <Row>
            <Table
              scroll={{
                x: 1300,
              }}
              columns={columns}
              dataSource={clientsList}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CavClientsList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CavClientsList)
