import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import {
  persistStore, persistReducer,
  createTransform,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from "./reducers"
import rootSaga from "./sagas"

const authTransform = createTransform(
  (inboundState) => {
    const { loading, ...rest } = inboundState; 
    return rest;
  },
  (outboundState) => outboundState,
  { whitelist: ['AppClients'] }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['AppClients'],
  transforms: [authTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store);
export default store
