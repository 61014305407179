import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Form, Table, Select, DatePicker, TimePicker } from "antd"
import { Container, Row, Button, Col } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import dayjs from "dayjs"


//i18n
import { withTranslation } from "react-i18next"
const { Option } = Select
const CavUsageReportDetails = props => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Time from",
      dataIndex: "timefrom",
    },
    {
      title: "Time to",
      dataIndex: "timeto",
    },
    {
      title: "Radiators in use",
      dataIndex: "radiatorsinuse",
    },
  ]
  const data = []
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      date: `11/2/2023`,
      timefrom: `${7 + i} Hrs`,
      timeto: 30 + i,
      radiatorsinuse: 2 + i,
    })
  }
  document.title = "Usage Report List | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Cave"
            breadcrumbItem="Usage Report List"
          />
          <Row>
            <Table    scroll={{
                x: 1300,
              }} columns={columns} dataSource={data} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CavUsageReportDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CavUsageReportDetails)
