import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Switch, Table } from "antd"
import { Container, Row, Button } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"



//i18n
import { withTranslation } from "react-i18next"

const BrandingList = props => {
  const navigate = useNavigate()
  const columns = [
    {
      title: "Brand Name",
      dataIndex: "brandname",
    },
    {
      title: "Primary Color",
      dataIndex: "primarycolor",
      render: data => {
        return (
          <div className="d-flex align-items-center ">
            <div
              style={{
                backgroundColor: data,
                width: "17px",
                height: "15px",
                top: "184px",
                left: "604px",
                borderRadius: "1px",
                marginRight: "2px",
              }}
            ></div>
            {data}
          </div>
        )
      },
    },
    {
      title: "Secondary Color",
      dataIndex: "secondarycolor",
      render: data => {
        return (
          <div className="d-flex align-items-center">
            <div
              style={{
                backgroundColor: data,
                width: "17px",
                height: "15px",
                top: "184px",
                left: "604px",
                borderRadius: "1px",
                marginRight: "2px",
              }}
            ></div>
            {data}
          </div>
        )
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contactnumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: data => (
        <Switch
          style={{
            backgroundColor: "green",
          }}
          checked={data}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: data => (
        <Button
          onClick={() => navigate("/edit-branding")}
          className="bg-primary text-white"
        >
          Edit
        </Button>
      ),
    },
  ]
  const data = []
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      brandname: `Edward King ${i}`,
      primarycolor: "#FF0000",
      secondarycolor: "#008000",
      email: "test@gmail.com",
      contactnumber: "4488812213123",
      status: true,
      action: i,
    })
  }
  document.title = "Branding List | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="My Radiator Buddy"
            breadcrumbItem="Branding List"
          />
          <Row>
            <Table    scroll={{
                x: 1300,
              }} columns={columns} dataSource={data} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BrandingList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BrandingList)
