import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import { actionTypes, getUserProfileSuccess, resetUserProfile } from "./actions"
import { notification } from "antd"

function* getUserProfileData() {
  try {
    const response = yield $authHost.get("users/profile")
    yield put(getUserProfileSuccess(response?.data?.data?.profile))
  } catch (error) {
    yield console.error(error)
  }
}

function* updateUserProfileData(action) {
  const { payload } = action
  const profilePayload = {
    first_name: payload?.first_name,
    last_name: payload?.last_name,
    mobile: payload?.phone,
    username: payload?.username
  }

  try {
    const response = yield $authHost.post("users/profile", profilePayload)
    if(response.data.code ===200){
      notification.success({
        message: response?.data.message,
        description: response?.data.message,
      })
      yield put(resetUserProfile())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetUserProfile())
    }
  }
}


function* userProfileRootSaga() {
  yield takeEvery(actionTypes.GET_USER_PROFILE, getUserProfileData)
  yield takeEvery(actionTypes.UPDATE_PROFILE, updateUserProfileData)
}

export default userProfileRootSaga
