import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { AES, enc } from "crypto-js"
import { useHistory } from "react-router-dom"
import { $host, $authHost } from "store/axios"
import { notification } from "antd"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  resetLoginStates,
  logoutUserSuccess,
} from "./actions"

function* loginUser({ payload: { user } }) {
  try {
    const response = yield $host.post(`auth/login`, {
      email: user.email,
      password: user.password,
    })
    const responseData = response.data.data.user

    if (responseData.level === 1001) {
      yield put(loginSuccess(responseData))
      const currentUser = responseData

      localStorage.setItem("isLoggedIn", true)

      const userString = JSON.stringify(currentUser)

      const timestamp = new Date().getTime()

      const userData = {
        data: userString,
        timestamp,
      }

      const encryptedUser = AES.encrypt(
        JSON.stringify(userData),
        process.env.REACT_APP_USER_DATA_ENC_KEY
      ).toString()

      localStorage.setItem("authUser", encryptedUser)
      localStorage.setItem("token", `${currentUser?.auth_token}`)
      sessionStorage.setItem("token", `${currentUser?.auth_token}`)
    } else {
      notification.error({
        message: "Not Allowed To Access",
        description: "This is Super Admin Portal",
      })
      yield put(resetLoginStates())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetLoginStates())
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    history("/")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
