import PropTypes from "prop-types"
import React, { useState } from "react"
import { Form, Input, Upload, Space, ColorPicker } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb"
// Custom Scrollbar
import SimpleBar from "simplebar-react"



//i18n
import { withTranslation } from "react-i18next"

const EditBranding = props => {
  const [form] = Form.useForm()
  document.title = "Add Clients | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
       
          <Row>
            <Col>
              <Form
                form={form}
                layout="vertical"
                size="large"
                // initialValues={{
                //   requiredMarkValue: requiredMark,
                // }}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={
                //   requiredMark === "customize"
                //     ? customizeRequiredMark
                //     : requiredMark
                // }
              >
                <Form.Item
                  label="University Name"
                  rules={[
                    {
                      required: true,
                      message: "Enter University Name",
                    },
                  ]}
                >
                  <Input placeholder="University Name" />
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="primarycolor"
                    label="Primary Color"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Primary Color!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <input
                      className="form-control form-control-color w-100"
                      type="color"
                      defaultValue="#1D2021"
                      id="example-color-input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="primarydark"
                    label="Primary Dark"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Primary Dark!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <input
                      className="form-control form-control-color w-100"
                      type="color"
                      defaultValue="#3F3D40"
                      id="example-color-input"
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="secondarycolor"
                    label="Secondary Color"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Secondary Color!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <input
                      className="form-control form-control-color w-100"
                      type="color"
                      defaultValue="#5C2A69"
                      id="example-color-input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="accentcolor"
                    label="Accent Color"
                    rules={[
                      {
                        // required: true,
                        message: "Please Enter Accent Color",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <input
                      className="form-control form-control-color w-100"
                      type="color"
                      defaultValue="#DB4433"
                      id="example-color-input"
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="backgroundcolor"
                    label="Background Color"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Background Color!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <input
                      className="form-control form-control-color w-100"
                      type="color"
                      defaultValue="#1D2021"
                      id="example-color-input"
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="secondarycolor"
                    label="Secondary Color"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Secondary Color!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <input
                      className="form-control form-control-color w-100"
                      type="color"
                      defaultValue="#080707"
                      id="example-color-input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="accentcolor"
                    label="Accent Color"
                    rules={[
                      {
                        // required: true,
                        message: "Please Enter Accent Color",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <input
                      className="form-control form-control-color w-100"
                      type="color"
                      defaultValue="#080707"
                      id="example-color-input"
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Space style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="bg-primary text-white px-4"
                      type="primary"
                      htmltype="submit"
                    >
                      Save
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditBranding.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EditBranding)
