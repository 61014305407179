import axios from "axios"

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://trust-electric-api.knarkzdev.com",
})

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://trust-electric-api.knarkzdev.com",
})

const authInterceptor = config => {
  if (typeof window !== "undefined") {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token")
    if (token) {
      config.headers["Authorization"] = `${token}`
    }
  }
  return config
}

$authHost.interceptors.request.use(authInterceptor)

export { $host, $authHost }
