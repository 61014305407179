import React from "react"

// Profile
import UserProfile from "pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import Register from "pages/Authentication/Register"
import ForgetPwd from "pages/Authentication/ForgetPassword"

// Dashboard
// Radiator Buddy
import RadiatorBuddyDashboard from "pages/radiatorBuddy/index"
import BrandingList from "pages/radiatorBuddy/branding/brandinglist"
import ClientsList from "pages/radiatorBuddy/clients/clients"
import NewClient from "pages/radiatorBuddy/clients/addNewClient"
import EditClient from "pages/radiatorBuddy/clients/editClient"
import EditBranding from "pages/radiatorBuddy/branding/editbranding"
import RadiatorList from "pages/radiatorBuddy/radiator/radiator"
import EditRadiator from "pages/radiatorBuddy/radiator/createEditRadiator"
import UsageReportList from "pages/radiatorBuddy/usageReport/usageReport"
import UsageReportDetails from "pages/radiatorBuddy/usageReport/usageReportDetail"
import BuddyTermsConditions from "pages/radiatorBuddy/terms-conditions/terms-conditions"
import BuddyPrivacyPolicy from "pages/radiatorBuddy/privacy-policy/privacy-policy"
import BuddyAboutUs from "pages/radiatorBuddy/about-us/about-us"

// letzero

import LetZeroDashboard from "pages/letZero/index"
import LetzeroLandlords from "pages/letZero/Landlords"
import LetzeroPrivacyPolicy from "pages/letZero/Privacypolicy"
import LetzeroTermsConditions from "pages/letZero/TermsConditions"
import LandlordForm from "pages/letZero/Landlords/landlordForm"

// Cave
import CaveDashboard from "pages/cave/index"
import CavClientsList from "pages/cave/clients/clients"
import CavNewClient from "pages/cave/clients/addNewClient"
import CavEditClient from "pages/cave/clients/editClient"
import CavUsageReportList from "pages/cave/usageReport/usageReport"
import CavNextKinList from "pages/cave/nextOfKin/nextOfKin"
import CreateEditNextKin from "pages/cave/nextOfKin/createEditNextKin"
import CavUsageReportDetails from "pages/cave/usageReport/usageReportDetail"
import CavSoSReports from "pages/cave/sosReports/sosReports"
import TermsConditions from "pages/cave/terms-conditions/terms-conditions"
import CaveAboutUs from "pages/cave/about-us/about-us"
import PrivacyPolicy from "pages/cave/privacy-policy/privacy-policy"
import CaveRadiators from "pages/cave/radiators/radiators"

const userRoutes = [
  // this route should be at the end of all other routes
  // Radiator
  { path: "/buddy-dashboard", component: <RadiatorBuddyDashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/branding-list", component: <BrandingList /> },
  { path: "/edit-branding", component: <EditBranding /> },
  { path: "/clients-list", component: <ClientsList /> },
  { path: "/new-client", component: <NewClient /> },
  { path: "/update-client", component: <EditClient /> },
  { path: "/radiators", component: <RadiatorList /> },
  { path: "/radiator-settings", component: <EditRadiator /> },
  { path: "/usage-report", component: <UsageReportList /> },
  { path: "/usage-report-details", component: <UsageReportDetails /> },
  { path: "/terms-conditions", component: <BuddyTermsConditions /> },
  { path: "/about-us", component: <BuddyAboutUs /> },
  { path: "/privacy-policy", component: <BuddyPrivacyPolicy /> },
  // letZero
  { path: "/letzero-dashboard", component: <LetZeroDashboard /> },
  { path: "/letzero-landlords", component: <LetzeroLandlords /> },
  { path: "/letzero-privacy-policy", component: <LetzeroPrivacyPolicy /> },
  { path: "/letzero-terms-condition", component: <LetzeroTermsConditions /> },
  { path: "/letzero-landlords001", component: <LandlordForm /> },
  // cave
  { path: "/cave-dashboard", component: <CaveDashboard /> },
  { path: "/cave-clients-list", component: <CavClientsList /> },
  { path: "/cave-new-client", component: <CavNewClient /> },
  { path: "/cave-update-client", component: <CavEditClient /> },
  { path: "/cave-kin-list", component: <CavNextKinList /> },
  { path: "/cave-create-edit-next-kin", component: <CreateEditNextKin /> },
  { path: "/cave-usage-report", component: <CavUsageReportList /> },
  { path: "/cave-usage-report-details", component: <CavUsageReportDetails /> },
  { path: "/cave-sos-reports", component: <CavSoSReports /> },
  { path: "/cave-terms-condition", component: <TermsConditions /> },
  { path: "/cave-about-us", component: <CaveAboutUs /> },
  { path: "/cave-privacy-policy", component: <PrivacyPolicy /> },
  { path: "/cave-radiators", component: <CaveRadiators /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
  // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  // { path: "/pages-404", component: <Pages404 /> },
  // { path: "/pages-500", component: <Pages500 /> },
]

export { userRoutes, authRoutes }
